import styled from 'styled-components';
import { Switch as AntdSwitch } from 'antd';

export const Switch = styled(AntdSwitch)`
  &.ant-switch[aria-checked='false'] {
    background-image: linear-gradient(to right, var(--switch-off-background-color), var(--switch-off-background-color)),
      linear-gradient(to right, var(--switch-off-background-color), var(--switch-off-background-color));

    .ant-switch-handle::before {
      background: var(--switch-off-track-color);
    }
  }

  &.ant-switch[aria-checked='true'] {
    background-image: linear-gradient(to right, var(--switch-on-background-color), var(--switch-on-background-color)),
      linear-gradient(to right, var(--switch-on-background-color), var(--switch-on-background-color));

    .ant-switch-handle::before {
      background: var(--switch-on-track-color);
    }
  }
`;
