import { FacilityType, IMedication, ITreatment } from '@app/interfaces/facility';
import { api } from './http.api';
import { IGetFacilitiesBody } from '@app/interfaces/breedingLocation';

const breedingJournalPathBase = '/web/admin/breeding-journal';

export const getBreedingJournalEntrypointData = async () => {
  try {
    const { data } = await api.get(`${breedingJournalPathBase}/entrypoint`);

    return { error: false, data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const getDevelopmentalStages = async () => {
  try {
    const { data } = await api.get(`${breedingJournalPathBase}/developmental-stage`);

    return { error: false, data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const getBatches = async () => {
  try {
    const { data } = await api.get(`${breedingJournalPathBase}/batches`);

    return { error: false, data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const createBatch = async (data: any) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/batches/`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const assignBatchToFacility = async (batchId: number, facilityId: number, layers: number[]) => {
  try {
    const response = await api.post(
      `${breedingJournalPathBase}/batches/assign/batch/${batchId}/facility/${facilityId}`,
      { batch: { id: batchId }, layers, type: FacilityType.Hatchery },
    );

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const assignBatchToZugerglass = async (batchId: number, facilityId: number) => {
  try {
    const response = await api.post(
      `${breedingJournalPathBase}/batches/assign/batch/${batchId}/facility/${facilityId}`,
      { batch: { id: batchId }, type: FacilityType.Zugerglass },
    );

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const getMedicationForBatch = async (batchId = '1') => {
  try {
    const { data } = await api.get(`${breedingJournalPathBase}/medication/${batchId}`);

    return { error: false, data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const emptyHatcheryBatch = async (stageId: number) => {
  try {
    const response = await api.delete(
      `${breedingJournalPathBase}/facility-stage/empty-hatchery/facility_stage/${stageId}`,
    );

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const emptyHatchery = async (facilityId: number) => {
  try {
    const response = await api.delete(`${breedingJournalPathBase}/facility/empty-hatchery/${facilityId}`);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const correctBatch = async (stageId: number, data: { number: number; developmentalStage?: { id: number } }) => {
  try {
    const response = await api.put(
      `${breedingJournalPathBase}/facility-stage/correction/facility_stage/${stageId}`,
      data,
    );

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const createMedicationForBatch = async (facilityStageId: number, data: Omit<IMedication, 'id'>) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/medication/`, {
      ...data,
      facilityStage: { id: facilityStageId },
    });

    return { error: false, data: response.data as IMedication };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const createTreatmentForBatch = async (facilityStageId: number, data: Omit<ITreatment, 'id'>) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/treatment/`, {
      ...data,
      facilityStage: { id: facilityStageId },
    });

    return { error: false, data: response.data as ITreatment };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

// --

export const moveFacilityStage = async (
  stageId: number,
  data: {
    number: number;
    facility: { id: number };
  }[],
) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/facility-stage/move/facility_stage/${stageId}`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const stockFacilityStage = async (
  stageId: number,
  data: {
    number: number;
    waterbody: { id: number };
  }[],
) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/facility-stage/stock/facility_stage/${stageId}`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const submitFishes = async (
  stageId: number,
  data: {
    number: number;
    breedingLocation: { id: number };
  },
) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/facility-stage/submit/facility_stage/${stageId}`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const assignWaterbody = async (stageId: number, waterbodyId: number) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/facility-stage/assign-waterbody/stage/${stageId}`, {
      waterbody: { id: waterbodyId },
    });

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const unassignWaterbody = async (stageId: number) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/facility-stage/unassign-waterbody/stage/${stageId}`);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const addWeightToFacilityStage = async (
  stageId: number,
  data: { weight: number; number: number; date: string },
) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/facility-stage/weight/${stageId}`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const editFacilityWeight = async (
  weightHistoryId: number,
  data: { weight: number; number: number; date: string },
) => {
  try {
    const response = await api.post(`${breedingJournalPathBase}/weight/${weightHistoryId}`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const getInventory = async () => {
  try {
    const { data } = await api.get(`${breedingJournalPathBase}/inventory`);

    return { error: false, data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const assignFacilityStageToInventory = async (stageId: number) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/facility-stage/inventory/facility_stage/${stageId}`);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const deleteInventory = async (inventoryId: number) => {
  try {
    const response = await api.delete(`${breedingJournalPathBase}/inventory/${inventoryId}`);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

// export const getSubmitBatchesList = async () => {
//   try {
//     const { data } = await api.get(`${breedingJournalPathBase}/submit/batches`);

//     return { error: false, data };
//   } catch (error) {
//     return { error: true, data: undefined };
//   }
// };

export const getSubmitList = async () => {
  try {
    const { data } = await api.get(`${breedingJournalPathBase}/submit/`);

    return { error: false, data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const obtainSubmittedBatch = async (submitId: number, facilityId: number, number: number, layers?: number[]) => {
  try {
    const response = await api.post(`${breedingJournalPathBase}/submit/obtain/${submitId}/facility/${facilityId}`, {
      number,
      layers,
    });

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const submitDirectBatch = async (data: any) => {
  try {
    const response = await api.post(`${breedingJournalPathBase}/submit/direct`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const createFacility = async (data: {
  name: string;
  location: { id: number };
  layersCount: number | null;
  type: FacilityType;
  parent?: { id: number } | null;
}) => {
  try {
    const response = await api.put(`${breedingJournalPathBase}/facility/`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const updateFacility = async (data: {
  id: number;
  name: string;
  location: { id: number };
  layersCount: number | null;
  type: FacilityType;
}) => {
  try {
    const response = await api.patch(`${breedingJournalPathBase}/facility/${data.id}`, data);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const deleteFacility = async (facilityId: number) => {
  try {
    const response = await api.delete(`${breedingJournalPathBase}/facility/${facilityId}`);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};

export const getFacilities = async (
  selectedBreedingLocationsIds: number[],
  selectedFishTypesIds: number[],
  selectedOriginsIds: number[],
  selectedAges: number[],
  selectedStagesIds: number[],
  selectedFishingDistrictsIds: number[],
  selectedActions: string[],
  fromFilter: string,
  toFilter: string,
) => {
  try {
    const body = {} as IGetFacilitiesBody;

    if (selectedBreedingLocationsIds.length) {
      body.location = selectedBreedingLocationsIds;
    }

    if (selectedFishTypesIds.length) {
      body.fishtypes = selectedFishTypesIds;
    }

    if (selectedOriginsIds.length) {
      body.origin = selectedOriginsIds;
    }

    if (selectedAges.length) {
      body.motherAge = selectedAges;
    }

    if (selectedStagesIds.length) {
      body.stages = selectedStagesIds;
    }

    if (selectedFishingDistrictsIds.length) {
      body.fishingDistricts = selectedFishingDistrictsIds;
    }

    if (selectedActions.length) {
      body.actions = selectedActions;
    }

    if (fromFilter) {
      body.dateFrom = fromFilter;
    }

    if (toFilter) {
      body.dateTo = toFilter;
    }

    const response = await api.post(`${breedingJournalPathBase}/facility-stage/filter`, body);

    return { error: false, data: response.data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};
