import React, { useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseImage } from '@app/components/common/BaseImage/BaseImage';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/inputs/VerificationCodeInput/VerificationCodeInput';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import { clearResetCodeError, setForgotPasswordVerificationCode } from '@app/store/slices/forgotPassword';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SecurityCodeForm.styles';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

const maxLength = 6;

export const SecurityCodeForm: React.FC<SecurityCodeFormProps> = ({ onBack, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { verificationCode, isVerificationCodeInvalid } = useAppSelector((state) => state.forgotPassword);

  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const navigateForward = useCallback(() => navigate('/auth/new-password'), [navigate]);

  const clearResetCodeErrorState = () => dispatch(clearResetCodeError());

  const setSecurityCode = (val: string) => {
    if (isVerificationCodeInvalid) {
      dispatch(setForgotPasswordVerificationCode(''));
      clearResetCodeErrorState();
    }
    dispatch(setForgotPasswordVerificationCode(val));
  };

  useEffect(() => {
    if (isVerificationCodeInvalid) {
      dispatch(setForgotPasswordVerificationCode(''));
      clearResetCodeErrorState();
    }
  }, []);

  useEffect(() => {
    if (verificationCode.length === maxLength && !isVerificationCodeInvalid) {
      if (onFinish) {
        onFinish();
      } else {
        navigateForward();
      }
    }
  }, [verificationCode, isVerificationCodeInvalid, navigateForward, onFinish, dispatch]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={onBack || navigateBack}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <BaseImage src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('securityCodeForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('common.verifCodeSent')}</S.VerifyEmailDescription>
          <VerificationCodeInput autoFocus validChars={'0-9'} onChange={setSecurityCode} />
          <Link to="/" target="_blank">
            <S.NoCodeText>{t('securityCodeForm.noCode')}</S.NoCodeText>
          </Link>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
