const groupIds: string[] = [];

export const defaultAcl = {
  modules: [
    {
      name: 'dashboard',
      features: [
        {
          name: 'breedingJournal',
          views: [],
          visible: true,
          isIndex: false,
        },
        {
          name: 'dashboardHomeView',
          views: [],
          visible: true,
          isIndex: true,
        },
        {
          name: 'observations',
          views: [
            {
              name: 'tableView',
              groupIds,
              visible: true,
              isIndex: false,
              permission: {
                create: null,
                edit: null,
                delete: true,
              },
              data: [],
              history: [],
              subViews: [
                {
                  name: 'download',
                  groupIds,
                  visible: true,
                  permission: {
                    create: null,
                    edit: null,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'yearlySummary',
                  groupIds,
                  visible: true,
                  permission: {
                    create: null,
                    edit: null,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
              ],
            },
            {
              name: 'observationView',
              groupIds,
              visible: true,
              isIndex: false,
              permission: {
                create: null,
                edit: true,
                delete: null,
              },
              data: [],
              history: [],
              subViews: [
                {
                  name: 'download',
                  groupIds,
                  visible: true,
                  permission: {
                    create: null,
                    edit: null,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'date',
                  groupIds,
                  visible: null,
                  permission: {
                    create: null,
                    edit: true,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'waterbody',
                  groupIds,
                  visible: null,
                  permission: {
                    create: null,
                    edit: true,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'inspector',
                  groupIds,
                  visible: null,
                  permission: {
                    create: null,
                    edit: false,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'category',
                  groupIds,
                  visible: null,
                  permission: {
                    create: null,
                    edit: true,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'description',
                  groupIds,
                  visible: null,
                  permission: {
                    create: null,
                    edit: true,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'images',
                  groupIds,
                  visible: null,
                  permission: {
                    create: true,
                    edit: null,
                    delete: true,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'location',
                  groupIds,
                  visible: null,
                  permission: {
                    create: null,
                    edit: true,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
              ],
            },
          ],
          visible: true,
          isIndex: false,
          history: [],
        },
        {
          name: 'inspections',
          views: [
            {
              name: 'tableView',
              groupIds,
              visible: true,
              isIndex: false,
              permission: {
                create: null,
                edit: null,
                delete: true,
              },
              data: [],
              history: [],
              subViews: [
                {
                  name: 'download',
                  groupIds,
                  visible: true,
                  permission: {
                    create: null,
                    edit: null,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
                {
                  name: 'yearlySummary',
                  groupIds,
                  visible: true,
                  permission: {
                    create: null,
                    edit: null,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
              ],
            },
            {
              name: 'invoiceDetails',
              groupIds,
              visible: null,
              isIndex: null,
              permission: {
                create: null,
                edit: null,
                delete: null,
              },
              data: [],
              history: [],
              subViews: [
                {
                  name: 'sendInvoice',
                  groupIds,
                  visible: null,
                  permission: {
                    create: true,
                    edit: null,
                    delete: null,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
              ],
            },
            {
              name: 'inspectionView',
              groupIds,
              visible: true,
              isIndex: false,
              permission: {
                create: null,
                edit: null,
                delete: null,
              },
              data: [],
              history: [],
              subViews: [
                {
                  name: 'cancelPenalty',
                  groupIds,
                  visible: null,
                  permission: {
                    create: null,
                    edit: null,
                    delete: true,
                  },
                  data: [],
                  history: [],
                  subViews: [],
                },
              ],
            },
          ],
          visible: true,
          isIndex: false,
          history: [],
        },
      ],
      visible: null,
      history: [],
      permission: null,
    },
  ],
} as const;
