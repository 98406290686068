interface IWhiteList {
  name: string;
  code: string;
  apiDomain: string;
  version: number;
}

export const configVersion = 6;

export const webshopRegex = /https:\/\/.*\.gr\.ch/;
export const scanAccountIdentifier = 'demo';

export const cantonWhitelist = [
  {
    name: 'Fishven Demo',
    code: 'fishven',
    apiDomain: 'demo-api.fishven.com',
    version: configVersion,
  },
] as IWhiteList[];
