import { AxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import crashlytics from "@react-native-firebase/crashlytics";
// import { IErrorData, IRejectedError } from '../../interfaces/apiError';
import { sendNewPasswordRequest, sendForgotPasswordEmailRequest } from '@app/api/forgotPassword';
import { ISendNewPasswordBody, IForgotPasswordEmailBody } from '@app/interfaces/forgotPassword';

export interface IState {
  sentEmailStatus: string;
  sentEmailLoading: boolean;
  emailSent: boolean;

  sentPasswordStatus: string;
  sentPasswordLoading: boolean;
  passwordSent: boolean;
  isVerificationCodeInvalid: boolean;

  email: string;
  verificationCode: string;
}

export const initialState = {
  sentEmailStatus: '',
  sentEmailLoading: false,
  emailSent: false,

  sentPasswordStatus: '',
  sentPasswordLoading: false,
  passwordSent: false,
  isVerificationCodeInvalid: false,

  email: '',
  verificationCode: '',
} as IState;

interface IErrorPayload {
  error_code: string;
}

const slice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    clearResetCodeError(state: IState) {
      state.isVerificationCodeInvalid = initialState.isVerificationCodeInvalid;
    },
    clearForgotPasswordState() {
      return initialState;
    },
    setForgotPasswordVerificationCode(state: IState, { payload }) {
      state.verificationCode = payload;
    },
    setForgotPasswordEmail(state: IState, { payload }) {
      state.email = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendForgotPasswordEmail.pending, (state: IState) => {
      state.sentEmailStatus = 'loading';
      state.sentEmailLoading = true;
      state.emailSent = true;
    });
    builder.addCase(sendForgotPasswordEmail.fulfilled, (state: IState) => {
      state.sentEmailLoading = false;
      state.emailSent = true;
    });
    builder.addCase(sendForgotPasswordEmail.rejected, (state: IState) => {
      state.emailSent = true;
      state.sentEmailLoading = false;
    });
    // ============================================================
    builder.addCase(sendNewPassword.pending, (state: IState) => {
      state.sentPasswordStatus = 'loading';
      state.sentPasswordLoading = true;
      state.isVerificationCodeInvalid = false;
      state.passwordSent = false;
    });
    builder.addCase(sendNewPassword.fulfilled, (state: IState) => {
      state.sentPasswordLoading = false;
      state.isVerificationCodeInvalid = false;
      state.passwordSent = true;
    });
    builder.addCase(sendNewPassword.rejected, (state: IState, { payload }) => {
      state.sentPasswordLoading = false;
      state.passwordSent = false;
      state.isVerificationCodeInvalid = (payload as IErrorPayload).error_code === 'INVALID_EMAIL_RESETCODE';
    });
  },
});

export const sendForgotPasswordEmail = createAsyncThunk(
  'forgotPasswordRequest',
  async (body: IForgotPasswordEmailBody, { rejectWithValue }) => {
    // crashlytics().log('Forgot password send email request started.');
    try {
      const response = await sendForgotPasswordEmailRequest(body);
      return response.data as unknown;
    } catch (error) {
      const err = error as AxiosError;
      //   crashlytics().log('Error while trying to send email for forgot password flow.');
      //   crashlytics().recordError(error as Error);

      return rejectWithValue(err.response?.data);
    }
  },
);

export const sendNewPassword = createAsyncThunk(
  'setNewPasswordRequest',
  async (body: ISendNewPasswordBody, { rejectWithValue }) => {
    // crashlytics().log('Forgot password send new password request started.');
    try {
      const response = await sendNewPasswordRequest(body);
      return response.data as unknown;
    } catch (error) {
      const err = error as AxiosError;
      const errorCode = err?.response?.data?.error_code ? (err.response.data.error_code as string) : '';
      //   crashlytics().log('Error while trying to send new password request data.');
      //   crashlytics().recordError(error as Error);
      return rejectWithValue({ error_code: errorCode } as IErrorPayload);
    }
  },
);

export const {
  clearForgotPasswordState,
  clearResetCodeError,
  setForgotPasswordEmail,
  setForgotPasswordVerificationCode,
} = slice.actions;

export const forgotPassword = slice.reducer;
