export const sanaMask = [
  /\d/,
  /\d/,
  /\d/,
  '-',
  [/\d/],
  [/\d/],
  [/\d/],
  [/\d/],
  '-',
  [/\d/],
  [/\d/],
  [/\d/],
  '/',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];
