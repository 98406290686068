import React, { useMemo } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { AppRoute } from '@app/interfaces/appRoute';
import { dashboardAclSelector } from '@app/store/slices/userSlice';
import { BREEDING_JOURNAL_PATH, HOME_PATH, INSPECTIONS_PATH, OBSERVATIONS_PATH } from '@app/constants/routerPaths';
import { withLoading } from '@app/hocs/withLoading.hoc';

const InspectionsPage = React.lazy(() => import('@app/pages/Inspections/InspectionsPage'));
const InspectionPage = React.lazy(() => import('@app/pages/Inspection/InspectionPage'));
const ObservationsPage = React.lazy(() => import('@app/pages/Observations/ObservationsPage'));
const ObservationPage = React.lazy(() => import('@app/pages/Observation/ObservationPage'));
const BreedingJournalPage = React.lazy(() => import('@app/pages/BreedingJournal/BreedingJournalPage'));
const DashboardPage = React.lazy(() => import('@app/pages/Dashboard/DashboardPage'));

const Inspections = withLoading(InspectionsPage);
const Inspection = withLoading(InspectionPage);

const Observations = withLoading(ObservationsPage);
const Observation = withLoading(ObservationPage);

const BreedingJournal = withLoading(BreedingJournalPage);

export const useAppRoutes = () => {
  const acl = useAppSelector(dashboardAclSelector);

  const [homeRoute, routesConfig] = useMemo(() => {
    const routes: AppRoute[] = [];

    if (!acl) {
      return [null, routes];
    }

    const indexFeature = acl?.features.find((feature) => feature.isIndex);
    const indexView = indexFeature?.views.find((view) => view.isIndex);

    const inspectionsFeature = acl?.features.find((feature) => feature.name === 'inspections');
    const observationsFeature = acl?.features.find((feature) => feature.name === 'observations');
    const breedingJournalFeature = acl?.features.find((feature) => feature.name === 'breedingJournal');
    const dashboardHomeFeature = acl?.features.find((feature) => feature.name === 'dashboardHomeView');

    if (inspectionsFeature?.visible) {
      if (inspectionsFeature?.views.find((view) => view.name === 'tableView')?.visible) {
        routes.push({
          path: INSPECTIONS_PATH,
          component: Inspections,
          isIndex: indexFeature?.name === 'inspections' && indexView?.name === 'tableView',
        });
      }

      if (inspectionsFeature?.views.find((view) => view.name === 'inspectionView')?.visible) {
        routes.push({
          path: `${INSPECTIONS_PATH}/:id`,
          component: Inspection,
        });
      }
    }

    if (observationsFeature?.visible) {
      if (observationsFeature?.views.find((view) => view.name === 'tableView')?.visible) {
        routes.push({
          path: OBSERVATIONS_PATH,
          component: Observations,
          isIndex: indexFeature?.name === 'observations' && indexView?.name === 'tableView',
        });
      }

      if (observationsFeature?.views.find((view) => view.name === 'observationView')?.visible) {
        routes.push({
          path: `${OBSERVATIONS_PATH}/:id`,
          component: Observation,
        });
      }
    }

    if (breedingJournalFeature?.visible) {
      routes.push({
        path: BREEDING_JOURNAL_PATH,
        component: BreedingJournal,
        isIndex: false,
      });
    }

    if (dashboardHomeFeature?.visible) {
      routes.push({
        path: HOME_PATH,
        component: DashboardPage,
        isIndex: dashboardHomeFeature.isIndex,
      });
    }

    return [routes.find((route) => route.isIndex), routes];
  }, [acl]);

  return [homeRoute, routesConfig] as const;
};
