import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Header } from '../../../header/Header';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import MainSider from '../sider/MainSider/MainSider';
import {
  MEDICAL_DASHBOARD_PATH,
  NFT_DASHBOARD_PATH,
  INSPECTIONS_PATH,
  OBSERVATIONS_PATH,
  BREEDING_JOURNAL_PATH,
} from '@app/constants/routerPaths';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './MainLayout.styles';

const MainLayout: React.FC = () => {
  const [isNoOffsetMainContent, setIsNoOffsetMainContent] = useState(false);
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop, isTablet, isBigScreen } = useResponsive();
  const location = useLocation();

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  useEffect(() => {
    const val =
      [INSPECTIONS_PATH, OBSERVATIONS_PATH, BREEDING_JOURNAL_PATH].includes(location.pathname) &&
      [isTablet, isBigScreen, isDesktop].some((val) => val);
    setIsNoOffsetMainContent(val);
  }, [location.pathname, isTablet, isBigScreen, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent
          id="main-content"
          $isTwoColumnsLayout={isTwoColumnsLayout}
          $isNoOffsetMainContent={isNoOffsetMainContent}
        >
          <Outlet />
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
