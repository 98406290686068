import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import { Loading } from '../common/Loading/Loading';
import { getAppConfig } from '@app/store/slices/config';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { fetchUser } from '@app/store/slices/userSlice';
import { Dates } from '@app/constants/Dates';
import { useAppRoutes } from './AppRouter.hooks';

const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const PersonalInfoPage = React.lazy(() => import('@app/pages/PersonalInfo/PersonalInfoPage'));
const Logout = React.lazy(() => import('./Logout'));

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
// const SecuritySettings = withLoading(SecuritySettingsPage);
// const Notifications = withLoading(NotificationsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();

  const { accessToken } = useAppSelector((state) => state.token);
  const { locale } = useAppSelector((state) => state.locale);

  const [isChangingLang, setIsChangingLang] = useState(false);

  const [isFetchingConfig, setIsFetchingConfig] = useState(true);
  const [configFetched, setConfigFetched] = useState(false);

  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [isUserFetched, setIsUserFetched] = useState(false);

  const isLoading = isFetchingConfig || isFetchingUser;

  useEffect(() => {
    void (async function () {
      try {
        if (!configFetched) {
          await dispatch(getAppConfig());
          setConfigFetched(true);
        }
        if (accessToken && !isUserFetched && !isFetchingUser) {
          setIsFetchingUser(true);
          await dispatch(fetchUser());
          setIsUserFetched(true);
          setIsFetchingUser(false);
        }
        setIsFetchingConfig(false);
      } catch (error) {
        console.log(error);
        console.log('Error while fetching config or user');
        setIsFetchingConfig(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (i18n.language !== locale && !isChangingLang) {
      setIsChangingLang(true);
      Dates.setLocale(locale);
      void i18n.changeLanguage(locale);
      setIsChangingLang(false);
    }
  }, [i18n, i18n.language, locale, isChangingLang]);

  useEffect(() => {
    if (accessToken && !isUserFetched && !isFetchingUser) {
      try {
        setIsFetchingUser(true);
        void dispatch(fetchUser());
        setIsUserFetched(true);
        setIsFetchingUser(false);
      } catch (error) {
        console.log(error);
      }
    }

    if (!accessToken && isUserFetched && !isFetchingUser) {
      setIsUserFetched(false);
    }
  }, [dispatch, accessToken, isUserFetched, isFetchingUser]);

  const [homeRoute, featureRoutesConfig] = useAppRoutes();
  const HomeComponent = homeRoute?.component || Error404;

  if (isLoading) {
    return <Loading />;
  }

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={protectedLayout}>
          {homeRoute && <Route index element={<HomeComponent />} />}

          {featureRoutesConfig.map((route) => {
            const Component = route.component;
            return <Route key={route.path} path={route.path} element={<Component />} />;
          })}

          {/* <Route path="import-paper-statistics" element={<ImportPaperStatistics />} /> */}
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            {/* <Route path="security-settings" element={<SecuritySettings />} /> */}
            {/* <Route path="notifications" element={<Notifications />} /> */}
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
