export const defaultDateFormatLong = 'YYYY-MM-DD';
export const defaultDateFormatShort = 'MM-DD';

export const defaultFormatLongDE = 'DD.MM.YYYY';
export const defaultFormatShortDE = 'DD.MM';

export const defaultFormatLongEN = 'MM/DD/YYYY';
export const defaultFormatShortEN = 'MM/DD';

export const defaultFormatLongFR = 'DD/MM/YYYY';
export const defaultFormatShortFR = 'DD/MM';

export const defaultFormatLongIT = 'DD/MM/YYYY';
export const defaultFormatShortIT = 'DD/MM';

export const filterDateFormat = 'YYYY-MM-DD HH:mm:ss';
