import React from 'react';
import styled from 'styled-components';
// import { GlobalSpinner } from '@app/components/common/GlobalSpinner/GlobalSpinner';
// import { useAppSelector } from '@app/hooks/reduxHooks';
// import { themeObject } from '@app/styles/themes/themeVariables';
import { ReactComponent as Spinner } from '@app/assets/icons/spinner.svg';

interface LoadingProps {
  size?: string;
  color?: string;
}

export const Loading: React.FC<LoadingProps> = ({}) => {
  // const theme = useAppSelector((state) => state.theme.theme);
  // const spinnerColor = color || themeObject[theme as keyof typeof themeObject].spinnerBase;

  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
