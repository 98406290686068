import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getResourcesData } from '@app/api/resources.api';
import { IErrorData } from '@app/interfaces/apiError';
import { IWaterBody } from '@app/interfaces/waterbody';

export interface IState {
  loading: boolean;
  waterbodies: IWaterBody[];
}

export const initialState = {
  loading: false,
  waterbodies: [],
} as IState;

const slice = createSlice({
  name: 'waterbodies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchWaterbodies.pending, (state: IState) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        fetchWaterbodies.fulfilled,
        (state: IState, { payload }: { payload: { waterbodies: IWaterBody[] } }) => ({
          ...state,
          loading: false,
          waterbodies: payload.waterbodies,
        }),
      )
      .addCase(fetchWaterbodies.rejected, (state: IState) => ({
        ...state,
        loading: false,
        waterbodies: initialState.waterbodies,
      }));
  },
});

export const fetchWaterbodies = createAsyncThunk('fetchWaterbodies', async (_: undefined, { rejectWithValue }) => {
  try {
    const response = await getResourcesData();

    const waterbodies = response.data.waterbodies as IWaterBody[];

    return { waterbodies };
  } catch (error) {
    const err = error as AxiosError;
    return rejectWithValue(err.response?.data as IErrorData);
  }
});

export const waterBodies = slice.reducer;
