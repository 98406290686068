import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { sendForgotPasswordEmail, setForgotPasswordEmail } from '@app/store/slices/forgotPassword';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './ForgotPasswordForm.styles';

interface ForgotPasswordFormData {
  email: string;
}

export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const { email } = useAppSelector((state) => state.forgotPassword);

  const handleSubmit = (values: ForgotPasswordFormData) => {
    setLoading(true);
    dispatch(sendForgotPasswordEmail(values))
      .unwrap()
      .then(() => {
        navigate('/auth/security-code');
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const initValues = { email };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('common.resetPassword')}</Auth.FormTitle>
        <S.Description>{t('forgotPassword.description')}</S.Description>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput
            placeholder={t('common.email')}
            onChange={(event) => dispatch(setForgotPasswordEmail(event.target.value))}
          />
        </Auth.FormItem>
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('forgotPassword.sendInstructions')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
