import { AxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBreedingJournalEntrypointData } from '@app/api/breedingJournal.api';
import { IErrorData } from '@app/interfaces/apiError';
import { IBreedingLocation } from '@app/interfaces/breedingLocation';
import { IDevelopmentalStage } from '@app/interfaces/developmentalStage';
import { IFishType } from '@app/interfaces/fishtype';
import { IPopulatedWaterbody } from '@app/interfaces/waterbody';
import { FacilityTypeResponse, IFacility } from '@app/interfaces/facility';

export interface IState {
  loading: boolean;
  breedingLocations: IBreedingLocation[];
  developmentalStages: IDevelopmentalStage[];
  fishtypes: IFishType[];
  waterbodies: IPopulatedWaterbody[];
  facilityTypes: FacilityTypeResponse[];
  facilities: IFacility[];
}

export const initialState = {
  loading: false,
  breedingLocations: [],
  developmentalStages: [],
  fishtypes: [],
  waterbodies: [],
  facilityTypes: [],
  facilities: [],
} as IState;

const slice = createSlice({
  name: 'breedingJournal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBreedingJournalData.pending, (state: IState) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        fetchBreedingJournalData.fulfilled,
        (
          state: IState,
          {
            payload,
          }: {
            payload: {
              breedingLocations: IBreedingLocation[];
              developmentalStages: IDevelopmentalStage[];
              fishtypes: IFishType[];
              waterbodies: IPopulatedWaterbody[];
              facilityTypes: FacilityTypeResponse[];
              facilities: IFacility[];
            };
          },
        ) => ({
          ...state,
          loading: false,
          breedingLocations: payload.breedingLocations,
          developmentalStages: payload.developmentalStages,
          fishtypes: payload.fishtypes,
          waterbodies: payload.waterbodies,
          facilityTypes: payload.facilityTypes,
          facilities: payload.facilities,
        }),
      )
      .addCase(fetchBreedingJournalData.rejected, (state: IState) => ({
        ...state,
        loading: false,
        breedingLocations: initialState.breedingLocations,
        developmentalStages: initialState.developmentalStages,
        fishtypes: initialState.fishtypes,
        waterbodies: initialState.waterbodies,
        facilityTypes: initialState.facilityTypes,
        facilities: initialState.facilities,
      }));
  },
});

export const fetchBreedingJournalData = createAsyncThunk(
  'fetchBreedingJournalData',
  async (_: undefined, { rejectWithValue }) => {
    try {
      const response = await getBreedingJournalEntrypointData();

      const breedingLocations = response.data.breedingLocations as IBreedingLocation[];
      const developmentalStages = response.data.developmentalStages as IDevelopmentalStage[];
      const fishtypes = response.data.fishtypes as IFishType[];
      const waterbodies = response.data.waterbodies as IPopulatedWaterbody[];
      const facilityTypes = response.data.facilityTypes as FacilityTypeResponse[];
      const facilities = response.data.facilities as IFacility[];

      return { breedingLocations, developmentalStages, fishtypes, waterbodies, facilityTypes, facilities };
    } catch (error) {
      const err = error as AxiosError;
      return rejectWithValue(err.response?.data as IErrorData);
    }
  },
);

export const breedingJournal = slice.reducer;
