import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LoginForm } from '@app/components/auth/LoginForm/LoginForm';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { clearForgotPasswordState } from '@app/store/slices/forgotPassword';

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { email, verificationCode } = useAppSelector((state) => state.forgotPassword);

  useEffect(() => {
    if (!!email || !!verificationCode) {
      dispatch(clearForgotPasswordState());
    }
  }, []);

  return (
    <>
      <PageTitle>{t('common.login')}</PageTitle>
      <LoginForm />
    </>
  );
};

export default LoginPage;
