import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { errorLoggingMiddleware } from '@app/store/middlewares/errorLogging.middleware';
import storage from 'redux-persist/lib/storage';
import reducers from '@app/store/slices';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['token', 'locale'],
};

const rootReducer = combineReducers(reducers);

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(errorLoggingMiddleware),
});

export const Store = store;

export const persistor = persistStore(Store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ReduxStore = typeof store;
