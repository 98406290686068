import { Language } from '@app/types/Language';
import { createSlice } from '@reduxjs/toolkit';
import { getAppConfig, IFulfilledConfigPayload } from './config';
import { cantonWhitelist } from '@app/constants/whitelist';

export interface IState {
  locale: Language;
  langIsSetByUser: boolean;
}

const slice = createSlice({
  name: 'locale',
  initialState: {
    locale: 'de',
  } as IState,
  reducers: {
    setLocale(state: IState, { payload }: { payload: Language }) {
      return {
        ...state,
        locale: payload,
        langIsSetByUser: true,
      };
    },
    setLocaleByConfig(state: IState, { payload }: { payload: Language }) {
      return {
        ...state,
        locale: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppConfig.fulfilled, (state, { payload }) => {
      if (!state.langIsSetByUser) {
        const payloadCantons = (payload as IFulfilledConfigPayload).cantons.filter((pc) =>
          cantonWhitelist.some((cwl) => pc.apiBaseUrl.includes(cwl.apiDomain)),
        );

        const matchedCurrentCanton = payloadCantons.find(
          (payloadCanton) => payloadCanton.accountId === (payload as IFulfilledConfigPayload).currentCanton?.accountId,
        );

        const payloadActiveCanton = matchedCurrentCanton?.accountId
          ? {
              ...(payload as IFulfilledConfigPayload).currentCanton,
              ...matchedCurrentCanton,
            }
          : payloadCantons.length === 1
          ? payloadCantons[0]
          : undefined;

        if (payloadActiveCanton?.languageDefault && payloadActiveCanton.languageDefault !== state.locale) {
          return {
            ...state,
            locale: payloadActiveCanton.languageDefault,
          };
        }
      }
    });
  },
});

export const locale = slice.reducer;
export const { setLocale } = slice.actions;
