import { AxiosError } from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import crashlytics from "@react-native-firebase/crashlytics";
import { api } from '@app/api/http.api';
import i18n, { applyTranslations } from '@app/i18n';
import { IErrorData } from '@app/interfaces/apiError';
import { ICanton } from '@app/interfaces/config';
import { cantonWhitelist, configVersion } from '@app/constants/whitelist';
import { RootState, Store } from '../store';
import { addProtocolIfNeeded } from '@app/utils/addProtocolPrefixIfNeeded';
import { sanaMask } from '@app/constants/sanaNumberFormatter';
import { seasonEndOffsetInDaysDefault } from '@app/constants/waterbody';
import {
  defaultFormatLongDE,
  defaultFormatShortDE,
  defaultFormatLongEN,
  defaultFormatLongFR,
  defaultFormatLongIT,
  defaultFormatShortEN,
  defaultFormatShortFR,
  defaultFormatShortIT,
} from '@app/constants/dateFormats';
import { getConfigRequest } from '@app/api/config';

export interface IState {
  cantons: ICanton[];
  currentCanton?: ICanton;
  initialConfigFetched: boolean;
}

export interface IFulfilledConfigPayload {
  currentCanton?: ICanton;
  cantons: ICanton[];
}

export const languagesListDefault = ['en', 'de', 'fr', 'it'];

export const cantonsInit = [
  {
    accountIconUrl: 'https://i.picsum.photos/id/124/50/50.jpg?hmac=_2zGwn9CyAOBnGyCPlV2SZ-c64jgrtlXXVrs78KBu6Q',
    accountId: 1,
    apiBaseUrl: 'https://demo-api.fishven.com',
    controlAppQRCodeActivated: false,
    languageDefault: 'de',
    languagesList: languagesListDefault,
    name: 'Graubünden',
    privacyPolicyVersion: 1,
    qrCodeLicenseActivated: true,
    seasonalQuota: 60,
    sessionEndHour: 23,
    sessionStartHour: 5,
    statisticsTimeSharesRanges: [5, 10, 14, 18, 23],
    supportEmail: 'support@fishven.com',
    webShopLink: 'https://intshop-ajf.gr.ch/de/',
  },
];

const initialState = {
  cantons: cantonsInit,
  currentCanton: undefined,
  initialConfigFetched: false,
} as IState;

const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setCanton(state, { payload }: { payload: string }) {
      const cantonName = payload;
      const targetCanton = state.cantons.find((cn: ICanton) => cn.name === cantonName);

      if (targetCanton && targetCanton.name === cantonName) {
        api.defaults.baseURL = addProtocolIfNeeded(targetCanton.apiBaseUrl);
        return {
          ...state,
          currentCanton: targetCanton,
        };
      }

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAppConfig.fulfilled, (state: IState, { payload }) => {
      const payloadCantons = (payload as IFulfilledConfigPayload).cantons.filter((pc) => {
        return cantonWhitelist.some((cwl) => {
          return pc.apiBaseUrl.includes(cwl.apiDomain);
        });
      });
      const matchedCurrentCanton = payloadCantons.find(
        (payloadCanton) => payloadCanton.accountId === state.currentCanton?.accountId,
      );

      const payloadActiveCanton = matchedCurrentCanton?.accountId
        ? {
            ...state.currentCanton,
            ...matchedCurrentCanton,
          }
        : payloadCantons.length === 1
        ? payloadCantons[0]
        : undefined;

      if (payloadActiveCanton?.translations) {
        applyTranslations(payloadActiveCanton?.translations, payloadActiveCanton?.languageDefault);
      }

      if (payloadActiveCanton?.apiBaseUrl) {
        api.defaults.baseURL = addProtocolIfNeeded(payloadActiveCanton?.apiBaseUrl);

        i18n.changeLanguage(payloadActiveCanton.languageDefault);
      }

      const cantons = payloadCantons.map((payloadCanton) => {
        const localValue = state.cantons.find((lc) => lc.accountId === payloadCanton.accountId);

        if (localValue?.accountId) {
          return {
            ...localValue,
            ...payloadCanton,
            agreedWithVersion: localValue.agreedWithVersion,
          };
        } else {
          return {
            ...payloadCanton,
          };
        }
      });

      return {
        ...state,
        cantons,
        currentCanton: payloadActiveCanton,
        initialConfigFetched: true,
      };
    });
  },
});

export const getAppConfig = createAsyncThunk('getAppConfig', async (_, { rejectWithValue }) => {
  try {
    try {
      const { data } = await getConfigRequest(configVersion);

      const cantons = (data as Array<{ configuration: ICanton }>).map((remoteItem) => ({
        ...remoteItem.configuration,
        length_input_mm:
          remoteItem.configuration.length_input_mm !== undefined ? remoteItem.configuration.length_input_mm : true,
        sanaMask: remoteItem.configuration.sanaMask != null ? remoteItem.configuration.sanaMask : sanaMask,
        seasonEndOffsetInDays: remoteItem.configuration.seasonEndOffsetInDays
          ? remoteItem.configuration.seasonEndOffsetInDays
          : seasonEndOffsetInDaysDefault,
        dateFormats: {
          de: {
            long: remoteItem.configuration?.dateFormats?.de?.long
              ? remoteItem.configuration.dateFormats.de.long
              : defaultFormatLongDE,
            short: remoteItem.configuration?.dateFormats?.de?.short
              ? remoteItem.configuration.dateFormats.de.short
              : defaultFormatShortDE,
          },
          en: {
            long: remoteItem.configuration?.dateFormats?.en?.long
              ? remoteItem.configuration.dateFormats.en.long
              : defaultFormatLongEN,
            short: remoteItem.configuration?.dateFormats?.en?.short
              ? remoteItem.configuration.dateFormats.en.short
              : defaultFormatShortEN,
          },
          it: {
            long: remoteItem.configuration?.dateFormats?.it?.long
              ? remoteItem.configuration.dateFormats.it.long
              : defaultFormatLongIT,
            short: remoteItem.configuration?.dateFormats?.it?.short
              ? remoteItem.configuration.dateFormats.it.short
              : defaultFormatShortIT,
          },
          fr: {
            long: remoteItem.configuration?.dateFormats?.fr?.long
              ? remoteItem.configuration.dateFormats.fr.long
              : defaultFormatLongFR,
            short: remoteItem.configuration?.dateFormats?.fr?.short
              ? remoteItem.configuration.dateFormats.fr.short
              : defaultFormatShortFR,
          },
        },
        observationMaxImagesLength: remoteItem.configuration.observationMaxImagesLength || 5,
      })) as Array<ICanton>;

      const store = Store.getState() as RootState;
      const currentCanton = store.config.currentCanton;

      return { cantons, currentCanton };
    } catch (error) {
      //   crashlytics().log('Error while trying config. (inner try catch)');
      //   if (error.isAxiosError) {
      //     crashlytics().log(`Config error code: ${error?.code}. Status code is: ${error?.response?.status}`);
      //   }
      //   crashlytics().recordError(error as Error);
      console.log(error);
    }
  } catch (error) {
    const err = error as AxiosError;
    // crashlytics().log('Error while trying config.');
    // crashlytics().recordError(error as Error);
    return rejectWithValue(err.response?.data as IErrorData);
  }
});

export const { setCanton } = slice.actions;

export const config = slice.reducer;
