import { LanguageType } from '@app/interfaces/interfaces';
import { setLocale } from '@app/store/slices/locale';
import { useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';

export const useLanguage = (): { language: LanguageType; setLanguage: (locale: LanguageType) => void } => {
  const dispatch = useAppDispatch();

  const { locale } = useAppSelector((state) => state.locale);

  const handleChangeLanguage = useCallback(
    (locale: LanguageType) => {
      dispatch(setLocale(locale));
    },
    [dispatch],
  );

  useEffect(() => {
    handleChangeLanguage(locale);
  }, [locale, handleChangeLanguage]);

  return useMemo(
    () => ({ language: locale as LanguageType, setLanguage: handleChangeLanguage }),
    [handleChangeLanguage, locale],
  );
};
