import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { clearForgotPasswordState, sendNewPassword } from '@app/store/slices/forgotPassword';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './NewPasswordForm.styles';

interface NewPasswordFormData {
  password: string;
  confirmPassword: string;
}

const initStates = {
  password: '',
  confirmPassword: '',
};

export const NewPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const { email, verificationCode, isVerificationCodeInvalid } = useAppSelector((state) => state.forgotPassword);

  const handleSubmit = (values: NewPasswordFormData) => {
    setLoading(true);
    dispatch(sendNewPassword({ email, password: values.password, resetCode: verificationCode }))
      .unwrap()
      .then(() => {
        navigate('/auth/login');
        dispatch(clearForgotPasswordState());
        notificationController.success({
          message: t('newPassword.successMessage'),
          description: t('newPassword.successDescription'),
        });
      })
      .catch(() => {
        // notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initStates}>
        <Auth.BackWrapper onClick={() => navigate(-1)}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <Auth.FormTitle>{t('newPassword.title')}</Auth.FormTitle>
        <S.Description>{t('newPassword.description')}</S.Description>
        <Auth.FormItem
          name="password"
          label={t('common.password')}
          rules={[
            { required: true, message: t('common.requiredField') },
            () => ({
              validator(_, value) {
                const isValid = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(value);

                return isValid
                  ? Promise.resolve()
                  : Promise.reject(new Error(t('profile.nav.securitySettings.notValidPassword')));
              },
            }),
          ]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} />
        </Auth.FormItem>
        <Auth.FormItem
          name="confirmPassword"
          label={t('common.confirmPassword')}
          dependencies={['password']}
          rules={[
            { required: true, message: t('common.requiredField') },
            ({ getFieldValue }) => ({
              validator(_, value) {
                const isValid = !value || getFieldValue('password') === value;

                return isValid ? Promise.resolve() : Promise.reject(new Error(t('common.confirmPasswordError')));
              },
            }),
          ]}
          hasFeedback
        >
          <Auth.FormInputPassword placeholder={t('common.confirmPassword')} />
        </Auth.FormItem>
        {isVerificationCodeInvalid ? (
          <Auth.ErrorWrapper>
            <Auth.ErrorText>{t('forms.stepFormLabels.resetCodeInvalid')}</Auth.ErrorText>
          </Auth.ErrorWrapper>
        ) : (
          <></>
        )}
        <BaseForm.Item noStyle>
          <S.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.resetPassword')}
          </S.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
