import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { RadioBtn } from '../SettingsOverlay/SettingsOverlay.styles';
import { useLanguage } from '@app/hooks/useLanguage';
import { BaseRadio } from '@app/components/common/BaseRadio/BaseRadio';
import { BaseSpace } from '@app/components/common/BaseSpace/BaseSpace';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { languagesListDefault } from '@app/store/slices/config';
import { useTranslation } from 'react-i18next';

const languageFlagMap = {
  en: 'GB',
  de: 'DE',
  it: 'IT',
  fr: 'FR',
};

export const LanguagePicker: React.FC = () => {
  const { t } = useTranslation();
  const { language, setLanguage } = useLanguage();

  const { currentCanton } = useAppSelector((state) => state.config);

  const languagesList = (currentCanton?.languagesList ? currentCanton.languagesList : languagesListDefault) as string[];

  return (
    <BaseRadio.Group defaultValue={language} onChange={(e) => setLanguage(e.target.value)}>
      <BaseSpace direction="vertical">
        {languagesList.map((langKey) => {
          return (
            <RadioBtn key={langKey} value={langKey}>
              <BaseSpace align="center">
                {t(`languages.${langKey}`)}
                <ReactCountryFlag svg countryCode={languageFlagMap[langKey as keyof typeof languageFlagMap]} />
              </BaseSpace>
            </RadioBtn>
          );
        })}
      </BaseSpace>
    </BaseRadio.Group>
  );
};
