import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import crashlytics from "@react-native-firebase/crashlytics";
// import messaging from "@react-native-firebase/messaging";
import { loginRequest } from '@app/api/token';
import { ITokens } from '@app/interfaces/token';
import { ILoginBody } from '../../interfaces/login';
import { AxiosError } from 'axios';

export interface IState extends ITokens {
  status: string;
  loading: boolean;
  wrongCredentials: boolean;
  isAuthenticated: boolean;
  checkedForSecureStoreTokens: boolean;
}

export const initialState = {
  accessToken: '',
  refreshToken: '',
  isAuthenticated: false,
  status: '',
  loading: false,
  wrongCredentials: false,
  checkedForSecureStoreTokens: false,
} as IState;

interface IErrorPayload {
  error_code: string;
}

// export const saveTokensInSecureStorage = async ({ accessToken, refreshToken }: ITokens) => {
//   await secureStoreSave('accessToken', accessToken);
//   await secureStoreSave('refreshToken', refreshToken);
// };

// const deleteTokensFromSecureStore = async () => {
//   await secureStoreDeleteByKey('accessToken');
//   await secureStoreDeleteByKey('refreshToken');
// };

const slice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setTokensFromSecureStore(state: IState, action: { payload: ITokens }) {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = true;
      state.checkedForSecureStoreTokens = true;
      state.wrongCredentials = initialState.wrongCredentials;
    },
    clearWrongCredentialsError(state: IState) {
      state.wrongCredentials = false;
    },
    setCheckedForSecureStoreTokens(state: IState, { payload }: { payload: boolean }) {
      state.checkedForSecureStoreTokens = payload;
    },
    setTokens(state: IState, action: { payload: ITokens }) {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchToken.pending, (state: IState) => {
      state.status = 'loading';
      state.loading = true;
      state.wrongCredentials = false;
      state.isAuthenticated = false;
    });
    builder.addCase(
      fetchToken.fulfilled,
      (state: IState, { payload: { accessToken, refreshToken } }: { payload: ITokens }) => {
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        state.loading = false;
        state.wrongCredentials = false;
        state.isAuthenticated = true;

        //   saveTokensInSecureStorage({ accessToken, refreshToken })
        //     .then(() => {})
        //     .catch(() => {});
      },
    );
    builder.addCase(fetchToken.rejected, (state: IState, { payload }) => {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
      state.wrongCredentials = (payload as IErrorPayload).error_code === 'UnauthorizedCode';
      state.isAuthenticated = false;
      state.loading = false;
    });

    builder.addCase(logout.fulfilled, (state: IState) => {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
      state.status = initialState.status;
      state.loading = initialState.loading;
      state.wrongCredentials = initialState.wrongCredentials;
      state.isAuthenticated = initialState.isAuthenticated;
    });

    builder.addCase(logout.rejected, (state: IState) => {
      state.accessToken = initialState.accessToken;
      state.refreshToken = initialState.refreshToken;
      state.status = initialState.status;
      state.loading = initialState.loading;
      state.wrongCredentials = initialState.wrongCredentials;
      state.isAuthenticated = initialState.isAuthenticated;
    });
  },
});

export const fetchToken = createAsyncThunk('token', async (body: ILoginBody, { rejectWithValue }) => {
  //   crashlytics().log('Fetch token / login request start.');
  try {
    const response = await loginRequest(body);

    // await messaging()
    //   .subscribeToTopic('LoggedInUsers')
    //   .then(() => console.log('Subscribed to LoggedInUserTopicName!'))
    //   .catch((e) => {
    //     console.log('error while subscribing to channel');
    //     console.log(e);
    //   });

    return response.data as ITokens;
  } catch (error) {
    const err = error as AxiosError;
    // crashlytics().log('Error while trying to fetch token / login.');
    // crashlytics().recordError(error as Error);
    const errorCode = err?.response?.data.error_code ? (err.response.data.error_code as string) : '';
    return rejectWithValue({ error_code: errorCode } as IErrorPayload);
  }
});

export const dispatchRefreshToken = createAsyncThunk('refreshToken', async (body: ILoginBody, { rejectWithValue }) => {
  //   crashlytics().log('Refresh token request start.');
  try {
    const response = await loginRequest(body);
    return response.data as ITokens;
  } catch (error) {
    const err = error as AxiosError;
    // crashlytics().log('Error while trying to request refresh token data.');
    // crashlytics().recordError(error as Error);
    const errorCode = err?.response?.data?.payload?.error_code ? (err.response.data.payload.error_code as string) : '';
    return rejectWithValue({ error_code: errorCode } as IErrorPayload);
  }
});

export const logout = createAsyncThunk('logout', async () => {
  try {
    // await deleteTokensFromSecureStore();
    // await secureStoreDeleteByKey('iduser');
    // await messaging()
    //   .unsubscribeFromTopic('LoggedInUsers')
    //   .then(() => console.log('Unsubscribed from LoggedInUserTopicName!'))
    //   .catch((e) => {
    //     console.log('error while unsubscribing to channel');
    //     console.log(e);
    //   });
    return null;
  } catch (error) {
    return null;
  }
});

export const { clearWrongCredentialsError, setTokensFromSecureStore, setCheckedForSecureStoreTokens, setTokens } =
  slice.actions;

export const token = slice.reducer;
