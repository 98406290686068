import { api } from './http.api';

const resourcesPathBase = '/web/admin/resources';

export const getResourcesData = async () => {
  try {
    const { data } = await api.get(resourcesPathBase);

    return { error: false, data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};
