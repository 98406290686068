import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { clearWrongCredentialsError, fetchToken } from '@app/store/slices/token';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './LoginForm.styles';

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(false);

  const { wrongCredentials } = useAppSelector((state) => state.token);

  const clearWrongCredentialsIfShown = () => {
    if (wrongCredentials) {
      dispatch(clearWrongCredentialsError());
    }
  };

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    clearWrongCredentialsIfShown();
    dispatch(fetchToken(values))
      .unwrap()
      .then(() => navigate('/'))
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      clearWrongCredentialsIfShown();
    };
  }, []);

  const handleAnyInputChange = () => clearWrongCredentialsIfShown();

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{t('common.login')}</Auth.FormTitle>
        <Auth.FormItem
          name="email"
          label={t('common.email')}
          rules={[
            { required: true, message: t('common.requiredField') },
            {
              type: 'email',
              message: t('common.notValidEmail'),
            },
          ]}
        >
          <Auth.FormInput placeholder={t('common.email')} onChange={() => handleAnyInputChange()} />
        </Auth.FormItem>
        <Auth.FormItem
          label={t('common.password')}
          name="password"
          rules={[{ required: true, message: t('common.requiredField') }]}
        >
          <Auth.FormInputPassword placeholder={t('common.password')} onChange={() => handleAnyInputChange()} />
        </Auth.FormItem>
        {wrongCredentials ? (
          <Auth.ErrorWrapper>
            <Auth.ErrorText>{t('forms.stepFormLabels.loginFailed')}</Auth.ErrorText>
          </Auth.ErrorWrapper>
        ) : (
          <></>
        )}
        <Auth.ActionsWrapper>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{t('common.forgotPass')}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {t('common.login')}
          </Auth.SubmitButton>
        </BaseForm.Item>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
